
  import { defineComponent, onBeforeMount, reactive, toRefs, computed } from 'vue';
  import {
    getALlSystemPage,
    deleteSystem,
    editSystem,
    addSystem,
    getSystemUser,
    authorizationSystem,
  } from '@/api/system';
  import { getAllUsers, getAdministratorsList } from '@/api/userinfo';
  import { ElMessageBox, ElMessage } from 'element-plus';
  import Cookies from 'js-cookie';
  import { IndexStateType } from './indexType';

  export default defineComponent({
    name: 'userManage',
    setup() {
      const state = reactive<IndexStateType>({
        searchForm: {
          name: '',
        },
        addForm: null,
        tableData: [],
        pageNumber: 0,
        pageSize: 10,
        totalCount: 0,
        organizationData: [],
        dialogEditVisible: false,
        editForm: {
          name: '',
          code: '',
          id: '',
          api: '',
          url: '',
          description: '',
          imgPath: '',
        },
        rules: {
          name: [
            {
              required: true,
              message: '请输入系统名称',
              trigger: 'blur',
            },
          ],
          code: [
            {
              required: true,
              message: '请输入系统代码',
              trigger: 'blur',
            },
          ],
          applicationName: [
            {
              required: true,
              message: '请输入服务名',
              trigger: 'blur',
            },
          ],
          id: [
            {
              required: true,
              message: '请输入id',
              trigger: 'change',
            },
          ],
          api: [
            {
              required: true,
              userName: '请输入api',
              trigger: 'blur',
            },
          ],
          url: [
            {
              required: true,
              userName: '请输入跳转链接',
              trigger: 'blur',
            },
          ],
        },
        dialogTitle: '新增系统',
        dialogType: 'add',
        ruleForm: null,
        uploadUrl: `${process.env.VUE_APP_API_ENDPOINT}system/img/upload`,
        headers: {
          'X-User-Token': Cookies.get('token'),
        },
        dialogSetVisible: false,
        administratorsList: [],
        userData: [],
        systemId: '',
      });

      const getData = async (params: any) => {
        const res: any = await getALlSystemPage(params);
        if (res.code === 0) {
          state.tableData = res.data.content;
          state.totalCount = res.data.content.length;
        }
      };
      const updateData = () => {
        const params = {
          pageNumber: state.pageNumber - 1,
          pageSize: state.pageSize,
        };
        console.log(state.searchForm);
        Object.keys(state.searchForm).forEach((key) => {
          if (state.searchForm[key]) {
            params[key] = state.searchForm[key];
          }
        });
        console.log(params);
        getData(params);
      };
      const handleAdd = () => {
        state.dialogEditVisible = true;
        state.dialogTitle = '新建系统';
        state.dialogType = 'add';
        state.editForm = {
          name: '',
          id: '',
          api: '',
          url: '',
          description: '',
          imgPath: '',
        };
      };
      const handleEdit = (row: any) => {
        state.dialogTitle = '编辑系统';
        state.dialogType = 'edit';
        state.dialogEditVisible = true;
        state.editForm = { ...row };
      };

      const handleDelete = (row: any) => {
        ElMessageBox.confirm(`确认删除`, '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            const res: any = await deleteSystem(row.id);
            if (res.code === 0) {
              ElMessage({
                type: 'success',
                message: res.msg,
              });
              updateData();
            }
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: 'Delete canceled',
            });
          });
      };
      const handleReset = (name: string) => {
        const form: any = state[name];
        form.resetFields();
      };

      const handleSubmit = () => {
        const form: any = state.ruleForm;
        form.validate(async (valid: any) => {
          if (valid) {
            if (state.dialogType === 'add') {
              const params = { ...state.editForm };
              console.log(state.editForm);
              const res: any = await addSystem(params);
              if (res.code === 0) {
                ElMessage({
                  message: '新建成功',
                  type: 'success',
                });
                updateData();
                state.dialogEditVisible = false;
                form.resetFields();
              } else {
                ElMessage({
                  message: res.msg,
                  type: 'warning',
                });
                form.resetFields();
              }
            } else {
              const params = { ...state.editForm };
              const res: any = await editSystem(params, state.editForm.id);
              if (res.code === 0) {
                ElMessage({
                  message: '更新成功',
                  type: 'success',
                });
                updateData();
                state.dialogEditVisible = false;
                form.resetFields();
              } else {
                ElMessage({
                  message: res.msg,
                  type: 'warning',
                });
                form.resetFields();
              }
            }
          }
        });
      };

      const handleClose = () => {
        state.dialogEditVisible = false;
        state.dialogSetVisible = false;
      };
      const handleAvatarSuccess = (res: any) => {
        if (res.code === 0) {
          ElMessage.success('上传成功');
          state.editForm.imgPath = res.data;
        }
      };
      const beforeAvatarUpload = (file: any) => {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          ElMessage.error('只能上传jpg,png图片');
        }
        if (!isLt2M) {
          ElMessage.error('上传大小不超过2M');
        }
        return isJPG && isLt2M;
      };
      const getUserData = async (id: string) => {
        const res: any = await getSystemUser(id);
        if (res.code === 0) {
          state.userData = res.data;
        }
      };

      const handleAdSubmit = async () => {
        const res: any = await authorizationSystem(state.systemId, state.administratorsList);
        if (res.code === 0) {
          ElMessage.success('授权成功');
          state.dialogSetVisible = false;
        } else {
          ElMessage.warning('授权成功');
          state.dialogSetVisible = false;
        }
      };

      onBeforeMount(() => {
        const params = {
          pageNumber: state.pageNumber,
          pageSize: state.pageSize,
        };
        getData(params);
      });
      return {
        ...toRefs(state),
        updateData,
        getData,
        handleAdd,
        handleEdit,
        handleDelete,
        handleReset,
        handleSubmit,
        handleClose,
        beforeAvatarUpload,
        handleAvatarSuccess,
        handleAdSubmit,
      };
    },
  });
